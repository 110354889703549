.container-wrapper {
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.container-wrapper__full_width {
  width: 100%;
  margin-left: 0;
}
.container-wrapper__shrink {
  width: calc(100% - 81px);
  margin-left: 74px;
}
